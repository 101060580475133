<script>
export default {
  data() {
    return {
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
  },
  methods: {
  }
};
</script>

<template>
  <div class="acitivity-item d-flex">
    <div class="flex-shrink-0">
      <div class="avatar-xs">
        <router-link :to="{ name: 'episode', params: { id: data.episode.id } }">
          <div class="avatar-title rounded-circle bg-primary text-white"><i class="ri-arrow-go-back-line"></i></div>
        </router-link>
      </div>
    </div>
    <div class="flex-grow-1 ms-2">
      <router-link :to="{ name: 'episode', params: { id: data.episode.id } }">
        <h6 class="mb-1"> Back to Episode</h6>
        <p class="text-muted mb-2">{{ data.episode.title }}</p>
      </router-link>
    </div>
  </div>
</template>