<script>
import ContentAbout from "../episode/_About.vue";
import Post from "../episode/_Post.vue";
import Back from "./_Back.vue";
import Comments from "./_Comments.vue";

export default {
  data() {
    return {
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
          episodes: [],
        }
      }
    }
  },
  components: {
    ContentAbout,
    Post,
    Back,
    Comments
  },
  mounted: function () {
  },
  methods: {
  },
  computed: {
  }
};
</script>

<template>
  <ContentAbout v-bind:data="data"></ContentAbout>

  <h5 class="card-title mb-0">Post</h5>
  <div class="acitivity-timeline py-3">
    <Back v-bind:data="data"></Back>
    <div class="acitivity-item d-flex">
      <Post ref="post" v-bind:data="data.post"></Post>
    </div>
  </div>
  
  <Comments v-bind:data="data"></Comments>
</template>
