<script>
import Table from "./_Table.vue";
import EpisodeItem from "../episodes/EpisodeItem.vue";
import PodcastItem from "../podcasts/PodcastItem.vue";

export default {
  data() {
    return {
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
    Table,
    EpisodeItem,
    PodcastItem,
  },
};
</script>

<template>
  <div class="team-list grid-view-filter row">

    <div class="col-12">
      <EpisodeItem v-bind:data="data.episode" v-bind:podcast="data.podcast"></EpisodeItem>
    </div>

    <div class="col-12" v-if="false">
      <PodcastItem v-bind:data="data.podcast"></PodcastItem>
    </div>

    <div class="col-12">
      <Table v-bind:data="data.episode"></Table>
    </div>

  </div>
</template>