<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/PageHeader.vue";
import appConfig from "../../../app.config";

import axios from 'axios';
import Container from "@/components/Container.vue";
import PostSidebar from "./PostSidebar.vue";
import PostContent from "./PostContent.vue";

export default {
  page: {
    title: "Post",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "Episode",
      data: {
        podcast: null,
        stat: null,
      }
    };
  },
  components: {
    Layout,
    PageHeader,
    Container,
    PostSidebar,
    PostContent,
  },
  created: function () {
    this.loadingPost();
  },
  mounted: function () {
  },
  methods: {
    loadingPost() {
      axios.get('/api/post/' + this.$route.params.id, { withCredentials: true })
        .then((response) => {
          this.data = response.data;
        })
        .catch(function () {
        });
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" v-if="false" />
    
    <Container>
      <template #sidebar>
        <PostSidebar v-bind:data="data" v-if="data.episode != null"></PostSidebar>
      </template>
      <template #content>
        <PostContent v-bind:data="data" v-if="data.episode != null"></PostContent>
      </template>
    </Container>
  </Layout>
</template>