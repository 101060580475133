<script>
import Comment from "./_Comment.vue";
import axios from 'axios';

export default {
  data() {
    return {
      isInvalidMemo: false,
      memo: ''
    }
  },
  props: {
    data: {
      type: Object,
      default: function () {
        return {
        }
      }
    }
  },
  components: {
    Comment
  },
  methods: {
    getPostId: function() {
      return this.data.post.messages[0].id;
    },
    validateForm: function () {
        this.isInvalidMemo = !this.memo;

        if(!(this.isInvalidMemo))
            return true;
        
        return false;
    },
    createComment: function() {
      var r = this.validateForm();
      if(r) {
          var data = {
              id_post: this.getPostId(),
              memo: this.memo,
          };

          const vm = this;
          axios.post('/api/comments/create', data, { withCredentials: true }).then(function () {
              vm.memo = '';
              vm.$router.go();
          });

      }
    }
  },
  computed: {
    canCreateComment: function() {
      return localStorage.getItem('user.id') !== null;
    }
  }
};
</script>

<template>
  <div class="card" v-if="true">
    <div class="card-header align-items-center d-flex">
      <h4 class="card-title mb-0 flex-grow-1">Comments</h4>
    </div>
    <div class="card-body">

      <div class="px-3 mx-n3 mb-2">
        <div class="d-flex mb-1" v-for="c in data.comments" v-bind:key="c">
          <Comment v-bind:data="c"></Comment>
        </div>
      </div>

      <form class="needs-validation" v-if="canCreateComment">
        <div class="row g-3">
          <div class="col-12">
            <label for="memo" class="form-label text-body">Leave a Comment</label>
            <textarea class="form-control" id="memo" v-model="memo" maxlength="4096" rows="3" placeholder="Enter your comment..." v-bind:class="{ 'is-invalid': isInvalidMemo }"></textarea>
            <div class="invalid-feedback">Please enter your message.</div>
          </div>
          <div class="col-12 text-end">
            <button type="button" class="btn btn-primary" @click="createComment">Post</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
